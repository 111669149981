import React, { Component } from 'react'
import "./sass/Announcement.scss"

export default class Announcement extends Component {
    render() {
        return (
            <div id="announcement">
                <div class="attn">
                    SERVING WESTCHESTER AND FAIRFIELD COUNTIES
                </div>
            </div>
        )
    }
}
